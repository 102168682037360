import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "../pages/home/home.component";
import {ClaimComponent} from "../pages/claim/claim.component";
import {AllocationSummaryComponent} from "../pages/allocation-summary/allocation-summary.component";
import {OtcStatsComponent} from "../pages/otc-stats/otc-stats.component";
import {CodeLoginComponent} from "../pages/code-login/code-login.component";
import {ChildrenManagementComponent} from "../pages/children-management/children-management.component";
import {InvalidLinkComponent} from "../pages/invalid-link/invalid-link.component";
import {ParentGuard} from "../guards/parent.guard";
import {LaunchpoolComponent} from "../pages/launchpool/launchpool.component";
import {ShowComponent} from '../pages/show/show.component';
import {MonitoringComponent} from '../pages/monitoring/monitoring.component';
import {StatsComponent} from '../pages/stats/stats.component';
import {MonitoringCompareComponent} from '../pages/monitoring-compare/monitoring-compare.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      // {path: '', component: ClaimComponent},
      {path: '', component: MonitoringComponent},
      // {path: 'allocation-summary', component: AllocationSummaryComponent},
      // {path: 'otc-stats', component: OtcStatsComponent},
      // // {path: 'launchpool', component: LaunchpoolComponent},
      // {path: 'manage', component: ChildrenManagementComponent, canActivate: [ParentGuard]},
      // {path: 'preview', component: ClaimComponent},
      // {path: 'invalid-link', component: InvalidLinkComponent},
      {path: 'monitoring', component: MonitoringComponent},
      {path: 'monitoring-compare', component: MonitoringCompareComponent},
      {path: 'stats', component: StatsComponent},

    ]
  },
  {path: 'login', component: CodeLoginComponent},
  {path: 'otc', component: ShowComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
