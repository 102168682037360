import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

import {catchError, map, zipAll} from 'rxjs/operators';
import {forkJoin, of, zip} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  endpoint = environment.monitoringEndpoint;
  statsLoaded: any;
  statsToCompareWithLoaded: any;
  analyses: any = [];
  priceOm: any = {};
  headers: HttpHeaders;
  projectName = 'om';
  snapshotsNames: any;

  baskets: any[] = [];
  basketsToCompareWith: any = [];
  totalMonitored = 0;
  totalMonitoredCompareWith = 0;
  comparingSnapshotName: any;
  currentNetwork: any = 'ETH';


  fetchDataSnapshots(projectName, snapshotFolderName): any[] {
    return [
      {name: 'Whales', endpoint: `/snapshot/${this.projectName}?snapshotFolderName=${snapshotFolderName}&addresses=whales`},
      {
        name: 'Treasury Liquid',
        endpoint: `/snapshot/${this.projectName}?snapshotFolderName=${snapshotFolderName}&addresses=treasury_liquid`
      },
      {
        name: 'Treasury Vested',
        endpoint: `/snapshot/${this.projectName}?snapshotFolderName=${snapshotFolderName}&addresses=treasury_vested`
      },
      {
        name: 'Treasury Staked',
        endpoint: `/snapshot/${this.projectName}?snapshotFolderName=${snapshotFolderName}_STAKED&addresses=treasury_staked`
      },
      // {
      //   name: 'Friendly Staked',
      //   endpoint: `/snapshot/${this.projectName}?snapshotFolderName=${snapshotFolderName}_STAKED&addresses=friendly_liquid`
      // },
      {name: 'Exchanges', endpoint: `/snapshot/${this.projectName}?snapshotFolderName=${snapshotFolderName}&addresses=exchanges`},
      {name: 'Infrastructure', endpoint: `/snapshot/${this.projectName}?snapshotFolderName=${snapshotFolderName}&addresses=infrastructure`},
      {name: 'Friendly Liquid', endpoint: `/snapshot/${this.projectName}?snapshotFolderName=${snapshotFolderName}&addresses=friendly`},
      {
        name: 'Friendly Staked',
        endpoint: `/snapshot/${this.projectName}?snapshotFolderName=${snapshotFolderName}_STAKED&addresses=friendly_staked`
      },
      {name: 'KoLs', endpoint: `/snapshot/${this.projectName}?snapshotFolderName=${snapshotFolderName}&addresses=kols`},

    ];
  }

  fetchData(projectName, chain): any[] {
    return [
      {name: 'Whales', endpoint: `/${this.projectName}?chain=${chain}&addresses=whales`},
      {name: 'Treasury Liquid', endpoint: `/${this.projectName}?chain=${chain}&addresses=treasury_liquid`},
      {name: 'Treasury Vested', endpoint: `/${this.projectName}?chain=${chain}&addresses=treasury_vested`},
      {name: 'Treasury Staked', endpoint: `/${this.projectName}_STAKED?chain=${chain}&addresses=treasury_liquid`},

      {name: 'Exchanges', endpoint: `/${this.projectName}?chain=${chain}&addresses=exchanges`},
      {name: 'Infrastructure', endpoint: `/${this.projectName}?chain=${chain}&addresses=infrastructure`},
      {name: 'Friendly Liquid', endpoint: `/${this.projectName}?chain=${chain}&addresses=friendly`},
      {name: 'Friendly Staked', endpoint: `/${this.projectName}_STAKED?chain=${chain}&addresses=friendly_staked`},
      {name: 'KoLs', endpoint: `/${this.projectName}?chain=${chain}&addresses=kols`},
    ];
  }

  constructor(private http: HttpClient, private router: Router) {
    let jwt = localStorage.getItem('jwt');
    if (!jwt) {
      this.router.navigate(['/login']);
    } else {
      //'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiS0ROZXh1cyIsImlhdCI6MTcwNTUyMzE3M30.eAJl4KGoOeacH9SV7-VZ9zc78PF1LA8bLYQBStN9ZOI'
      this.initiate(jwt, false);
    }

  }


  login(password) {
    this.http.post(`${this.endpoint}/login`, {password}).subscribe((data: any) => {
      if (data.token) {
        localStorage.setItem('jwt', data.token);
        this.initiate(data.token);

      }
    });
  }


  fetchSnapshots() {
    this.http.get(`${this.endpoint}/balances/list-snapshots/${this.projectName}`, {headers: this.headers}).subscribe(data => {
      this.snapshotsNames = data;
    });
  }

  async timeout() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 300)
    })

  }


  async fetchSnapshot(snapshotFolderName) {
    // this.fetchDataSnapshots(this.projectName, snapshotFileName).subscribe(data => {
    //   this.basketsToCompareWith = data;
    // })
    this.statsToCompareWithLoaded = false;
    //await this.timeout();
    this.baskets.forEach(q => {
      q.data.addresses.forEach(address => {
        delete address.deltaFound;
      })
      delete q.show;
    })
    this.comparingSnapshotName = snapshotFolderName;
    this.totalMonitoredCompareWith = 0;
    this.basketsToCompareWith = [];
    this.basketsToCompareWith = this.fetchDataSnapshots(this.projectName, snapshotFolderName);

    let obs = this.basketsToCompareWith.map(basket => this.http.get(`${this.endpoint}/balances${basket.endpoint}`, {headers: this.headers}).pipe(catchError((err) => {
      return of({addresses: []});
    }), map((data: any) => {
      if (data.metadata.project_name.split('_').length == 1) {
        this.totalMonitoredCompareWith += data.amount || 0;
      }
      console.log(this.totalMonitoredCompareWith)
      basket.data = data;
    })));
    forkJoin(obs).subscribe(data => {
      console.log(data);
      this.statsToCompareWithLoaded = true;
      this.findDeltas();
      console.log(this.totalMonitoredCompareWith)
    });

  }

  findDeltas() {
    let flat = [];
    this.baskets.forEach(currentBasket => {
      flat.push(...currentBasket.data.addresses);
    });

    let flatSnapshot = [];
    this.basketsToCompareWith.forEach(currentBasket => {
      currentBasket.deltas = [];
      currentBasket.totalDelta = 0;
      currentBasket.data.addresses.forEach(q => {
        q.basket = currentBasket;
        flatSnapshot.push(q);
      })
      //flatSnapshot.push(...currentBasket.data.addresses);
    });


    flatSnapshot.forEach(snapshotBasketAddress => {
      let found = flat.find(q => q.address === snapshotBasketAddress.address);
      if (found && found.amount !== snapshotBasketAddress.amount) {
        console.log(`Delta found ${found} ${snapshotBasketAddress}`);
        snapshotBasketAddress.deltaFound = true;
        found.deltaFound = true;
        snapshotBasketAddress.delta = found.amount - snapshotBasketAddress.amount;
        snapshotBasketAddress.basket.deltas.push([{address: snapshotBasketAddress.address, delta: snapshotBasketAddress.delta}])
        snapshotBasketAddress.basket.totalDelta++;
      } else if (!found) {
        snapshotBasketAddress.moved = true;
        console.log(`Missing ${snapshotBasketAddress}`);
      }
    });
  }

  initiate(jwt, navigate = true) {
    //let projectName = this.projectName;
    this.headers = new HttpHeaders({'Authorization': jwt});
    this.http.get(`${this.endpoint}/analysis/${this.projectName}`, {headers: this.headers}).subscribe(data => {
      this.analyses = data;
    });
    this.http.get(`${this.endpoint}/price/${this.projectName}`, {headers: this.headers}).subscribe(data => {
      this.priceOm = data;
    });
    this.fetchBaskets();
    this.fetchSnapshots();
    if (navigate) {
      this.router.navigate(['/stats']);
    }

  }

  fetchBaskets() {
    this.totalMonitored = 0;
    this.baskets = this.fetchData(this.projectName, this.currentNetwork);
    this.baskets.forEach((basket) => {
      this.http.get(`${this.endpoint}/balances${basket.endpoint}`, {headers: this.headers}).subscribe((data: any) => {
        if (data.metadata.project_name.split('_').length == 1) { //Bullshit hack to remove the basked marked with underscore _VESTED from counting
          this.totalMonitored += data.amount;
        }
        basket.data = data;
        this.statsLoaded = true;
        //Load first snapshot for comparison
        if (this.snapshotsNames.length > 0) {
          this.fetchSnapshot(this.snapshotsNames[0])
        }
      });
    });
  }

  initiateSnapshot() {
    this.http.get(`${this.endpoint}/snapshot/initiate`, {headers: this.headers}).subscribe(data => {
      setTimeout(() => {
        this.fetchSnapshots()
      }, 3000)

    })
  }
}
